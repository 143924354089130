import Vue from 'vue'
import Router from 'vue-router'
import LoginLayout from '@/layouts/Login'
import MainLayout from '@/layouts/Main'
import store from '@/store'
import moment from 'moment'
import queryString from 'query-string'

Vue.use(Router)

const router = new Router({
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: '/notifications/list',
      component: MainLayout,
      meta: {
        authRequired: true,
        hidden: true,
      },
      children: [
        // master
        {
          path: '/master/companies',
          meta: {
            title: '会社一覧',
          },
          component: () => import('./views/company/list'),
        },
        {
          path: '/master/companies/create',
          name: 'companyCreate',
          meta: {
            title: '会社作成',
          },
          props: {
            submitType: 'post',
            submitString: '作成',
          },
          component: () => import('./views/company/create'),
        },
        { // 使っていないはず
          path: 'master/companies/:id',
          name: 'companyShow',
          meta: {
            title: '会社詳細',
          },
          component: () => import('./views/company/show'),
        },
        {
          path: 'master/companies/:id/update',
          name: 'companyUpdate',
          meta: {
            title: '会社編集',
          },
          props: {
            submitType: 'put',
            submitString: '更新',
          },
          component: () => import('./views/company/update'),
        },
        {
          path: '/master/company_groups',
          name: 'companyGroups',
          meta: {
            title: '会社グループ一覧',
          },
          component: () => import('./views/company_group/list'),
        },
        {
          path: '/master/company/create_group',
          name: 'companyGroupCreate',
          meta: {
            title: '会社グループ作成',
          },
          props: {
            submitType: 'post',
            submitString: '作成',
          },
          component: () => import('./views/company_group/create'),
        },
        {
          path: '/master/company/:id/update_group',
          name: 'companyGroupUpdate',
          meta: {
            title: '会社グループ編集',
          },
          props: {
            submitType: 'put',
            submitString: '更新',
          },
          component: () => import('./views/company_group/update'),
        },
        {
          path: '/master/company_groups/:id',
          name: 'companyGroupShow',
          meta: {
            title: '会社グループ',
          },
          component: () => import('./views/company_group/show'),
        },
        {
          path: '/master/company_groups/:id/manage_company',
          name: 'companyGroupManageCompany',
          meta: {
            title: '会社グループ所属の会社管理',
          },
          component: () => import('./views/company_group/manage_company'),
        },
        {
          path: '/master/company_groups/:id/create_timer',
          name: 'companyGroupCreateTimer',
          meta: {
            title: '会社グループタイマー作成',
          },
          props: {
            submitType: 'post',
            submitString: '作成',
          },
          component: () => import('./views/company_group/create_timer'),
        },
        {
          path: '/master/company_groups/timers/:id/update',
          name: 'companyGroupUpdateTimer',
          meta: {
            title: '会社グループタイマー更新',
          },
          props: {
            submitType: 'put',
            submitString: '更新',
          },
          component: () => import('./views/company_group/update_timer'),
        },
        {
          path: '/master/statistics',
          name: 'masterStatistics',
          meta: {
            title: '統計情報',
          },
          component: () => import('./views/master/statistics'),
        },
        {
          path: '/master/monthly_dashboard',
          name: 'monthlyDashboard',
          meta: {
            title: '月別ダッシュボード',
          },
          component: () => import('./views/master/monthly_dashboard'),
        },
        {
          path: '/users/store/force',
          name: 'userStoreForce',
          meta: {
            title: 'ユーザー作成',
          },
          component: () => import('./views/master/user_create'),
        },
        {
          path: '/users/store/force/bulk',
          name: 'userStoreForceBulk',
          meta: {
            title: 'ユーザー一括作成',
          },
          component: () => import('./views/master/user_create_bulk'),
        },
        {
          path: '/master/setting',
          name: 'masterSetting',
          meta: {
            title: 'AI-Contact設定',
          },
          component: () => import('./views/master/setting'),
        },
        {
          path: '/master/history',
          name: 'masterHistory',
          meta: {
            title: 'ログ検索',
          },
          component: () => import('./views/history/master'),
        },
        {
          path: '/master/tools',
          name: 'masterTool',
          meta: {
            title: 'ツール',
          },
          component: () => import('./views/master/tools'),
        },
        {
          path: '/master/trial2',
          meta: {
            title: '違反マップ',
          },
          component: () => import('./views/master/trial2'),
        },

        // company
        {
          path: '/company',
          name: 'company',
          meta: {
            title: '会社情報',
          },
          component: () => import('./views/company/show'),
        },
        {
          path: '/company/update',
          name: 'myCompanyUpdate',
          meta: {
            title: '会社編集',
          },
          props: {
            submitType: 'put',
            submitString: '更新',
          },
          component: () => import('./views/company/update'),
        },
        {
          path: '/company/analysis_parameter',
          name: 'analysisParameter',
          meta: {
            title: '解析パラメータ設定',
          },
          component: () => import('./views/company/analysisParameterUpdate'),
        },

        // Branch
        {
          path: '/branches/:id',
          name: 'branchShow',
          meta: {
            title: '支店詳細',
          },
          component: () => import('./views/branch/show'),
        },
        {
          path: '/branches/create',
          name: 'branchCreate',
          meta: {
            title: '支店作成',
            submitType: 'post',
            submitString: '作成',
          },
          component: () => import('./views/branch/create'),
        },
        {
          path: '/branches/:id/update',
          name: 'branchUpdate',
          meta: {
            title: '支店編集',
            submitType: 'put',
            submitString: '更新',
          },
          component: () => import('./views/branch/update'),
        },
        {
          path: '/branches/create_bulk',
          name: 'branchCreateBulk',
          meta: {
            title: '支店一括作成',
          },
          component: () => import('./views/branch/create_bulk'),
        },

        // User
        {
          path: '/users',
          name: 'users',
          meta: {
            title: 'ユーザー一覧',
          },
          component: () => import('./views/user/list'),
        },
        {
          path: '/users/create_bulk',
          name: 'userCreateBulk',
          meta: {
            title: 'ユーザー一括作成',
          },
          component: () => import('./views/user/create_bulk'),
        },
        {
          path: '/users/create',
          name: 'userCreate',
          meta: {
            title: 'ユーザー作成',
            submitType: 'post',
            submitString: '作成',
          },
          component: () => import('./views/user/create'),
        },
        {
          path: '/users/:id/update',
          name: 'userUpdate',
          meta: {
            title: 'ユーザー編集',
            submitType: 'put',
            submitString: '更新',
          },
          component: () => import('./views/user/update'),
        },
        {
          path: '/users/:id/user_read_range',
          name: 'userReadRange',
          meta: {
            title: 'ユーザーの追加閲覧可能グループ',
          },
          component: () => import('./views/user/manage_group'),
        },
        {
          path: '/users/:id',
          name: 'userShow',
          meta: {
            title: 'ユーザー詳細',
          },
          component: () => import('./views/user/show'),
        },
        {
          path: '/2fa/show',
          name: '2faShow',
          meta: {
            title: '2段階認証設定の表示',
          },
          component: () => import('./views/2fa/show'),
        },
        {
          path: '/driver_lisence/create_bulk',
          name: 'driversLisenceCreateBulk',
          meta: {
            title: '運転免許作成',
          },
          component: () => import('./views/driver_lisence/create_bulk'),
        },

        // Vehicle
        {
          path: '/vehicles/list',
          name: 'vehiclesList',
          meta: {
            title: '車両一覧',
          },
          component: () => import('./views/vehicle/list'),
        },
        {
          path: '/vehicles/:id',
          name: 'vehicleShow',
          meta: {
            title: '車両詳細',
          },
          component: () => import('./views/vehicle/show'),
        },
        {
          path: '/vehicles/create/:branch_id',
          name: 'vehicleCreate',
          meta: {
            title: '車両作成',
            submitType: 'post',
            submitString: '作成',
          },
          component: () => import('./views/vehicle/create'),
        },
        {
          path: '/vehicles/:id/update',
          name: 'vehicleUpdate',
          meta: {
            title: '車両編集',
            submitType: 'put',
            submitString: '更新',
          },
          component: () => import('./views/vehicle/update'),
        },
        {
          path: '/user/vehicles',
          name: 'userVehicle',
          meta: {
            title: '運転',
          },
          component: () => import('./views/vehicle/drive'),
        },
        {
          path: '/vehicles/create_bulk',
          name: 'vehicleCreateBulk',
          meta: {
            title: '車両一括作成',
          },
          component: () => import('./views/vehicle/create_bulk'),
        },

        // Branch_User
        {
          path: '/branches/:id/users',
          name: 'belongUserManage',
          meta: {
            title: '支店に所属するユーザー管理',
          },
          component: () => import('./views/branch_user/manage_branch'),
        },
        {
          path: '/users/:id/branches',
          name: 'belongBranchManage',
          meta: {
            title: 'ユーザーの所属する支店管理',
          },
          component: () => import('./views/branch_user/manage_user'),
        },

        // Caution Drivers
        {
          path: '/caution/drivers/unconfirmed',
          name: 'cautionDriversUnconfirmed',
          meta: {
            title: '過労運転管理 未確認一覧',
          },
          component: () => import('./views/caution/drivers/unconfirmed'),
        },
        {
          path: '/caution/drivers/confirmed',
          name: 'cautionDriversConfirmed',
          meta: {
            title: '過労運転管理 確認済一覧',
          },
          component: () => import('./views/caution/drivers/confirmed'),
        },

        // History
        {
          path: '/histories',
          name: 'history',
          meta: {
            title: '操作履歴',
            userHidden: true,
          },
          component: () => import('./views/history/user'),
        },

        // DriveData
        {
          path: '/drive_monitor',
          name: 'driveMonitor',
          meta: {
            title: '運行状況',
          },
          component: () => import('./views/drive_data/monitor'),
        },
        {
          path: '/drive_data',
          name: 'driveData',
          meta: {
            title: '運行履歴',
          },
          component: () => import('./views/drive_data/list'),
        },
        {
          path: '/drive_data/:id',
          name: 'driveMap',
          meta: {
            title: '運行情報',
            target: 'drive_data',
          },
          component: () => import('./views/drive_data/map'),
        },
        {
          path: '/drive_data/user/:user_id/:date',
          name: 'driveMapUser',
          meta: {
            title: '運行情報',
            target: 'user',
          },
          component: () => import('./views/drive_data/map'),
        },
        {
          path: '/drive_data/vehicle/:vehicle_id/:date',
          name: 'driveMapVehicle',
          meta: {
            title: '運行情報',
            target: 'vehicle',
          },
          component: () => import('./views/drive_data/map'),
        },

        // Dashboard
        {
          path: '/dashboard',
          name: 'dashboard',
          meta: {
            title: '遵守状況確認',
          },
          component: () => import('./views/dashboard'),
        },
        {
          path: '/dashboard/business',
          name: 'dashboardBusiness',
          meta: {
            title: 'ダッシュボード',
          },
          component: () => import('./views/dashboard/business'),
        },

        // Setting
        {
          path: '/setting',
          name: 'setting',
          meta: {
            title: '設定',
          },
          component: () => import('./views/setting'),
        },

        {
          path: '/manual',
          name: 'manual',
          meta: {
            title: 'マニュアル',
          },
          component: () => import('./views/manual'),
        },

        // Group
        {
          path: '/groups/create',
          name: 'groupCreate',
          meta: {
            title: 'グループ作成',
            submitType: 'post',
            submitString: '作成',
          },
          component: () => import('./views/group/create'),
        },
        {
          path: '/groups/:id/update',
          name: 'groupUpdate',
          meta: {
            title: 'グループ編集',
            submitType: 'put',
            submitString: '更新',
          },
          component: () => import('./views/group/update'),
        },
        {
          path: '/groups/:id/users',
          name: 'groupBelongUser',
          meta: {
            title: 'グループに所属するユーザー管理',
          },
          component: () => import('./views/group/belong_users'),
        },
        {
          path: '/groups/:id/vehicles',
          name: 'groupBelongVehicle',
          meta: {
            title: 'グループに所属する車両管理',
          },
          component: () => import('./views/group/belong_vehicles'),
        },

        // DriveList
        {
          path: '/drive_list',
          name: 'driveList',
          meta: {
            title: 'ドライブリスト',
          },
          component: () => import('./views/drive_list'),
        },

        // AlcoholCheck
        {
          path: '/alcohol_check',
          name: 'alcoholCheck',
          meta: {
            title: '点呼記録簿',
          },
          component: () => import('./views/alcohol_check'),
        },
        {
          path: '/alcohol_check/unconfirmed',
          name: 'alcoholCheckUnconfirmed',
          meta: {
            title: '点呼記録簿（未確認）',
          },
          component: () => import('./views/alcohol_check/unconfirmed'),
        },

        // DrivingSchedule
        {
          path: '/driving_schedule',
          name: 'drivingSchedule',
          meta: {
            title: '運行計画表',
          },
          component: () => import('./views/driving_schedule'),
        },

        // VehicleReservations
        {
          path: '/vehicle/reservations',
          name: 'vehicleReservations',
          meta: {
            title: '車両予約',
          },
          component: () => import('./views/vehicle/reservations'),
        },

        // InsurancePolicy
        {
          path: '/insurance_policies',
          name: 'insurancePolicies',
          meta: {
            title: '保険証券',
          },
          component: () => import('./views/insurance_policies'),
        },

        // Operator
        {
          path: '/operator/roll_call',
          name: 'operatorRollCall',
          meta: {
            title: '点呼代行',
          },
          component: () => import('./views/operator/roll_call'),
        },

        // Notification
        {
          path: '/master/notifications/list',
          name: 'masterNotifications',
          meta: {
            title: 'お知らせ一覧',
          },
          component: () => import('./views/master/notifications/list'),
        },
        {
          path: '/master/notifications/create',
          name: 'notificationCreate',
          meta: {
            title: 'お知らせ作成',
          },
          component: () => import('./views/master/notifications/create'),
        },
        {
          path: 'master/notifications/:id/update',
          name: 'notificationUpdate',
          meta: {
            title: 'お知らせ編集',
            submitType: 'put',
            submitString: '更新',
          },
          component: () => import('./views/master/notifications/update'),
        },
        {
          path: '/notifications/list',
          name: 'notifications',
          meta: {
            title: 'お知らせ一覧',
          },
          component: () => import('./views/notifications/list'),
        },
        {
          path: '/notifications/:id',
          name: 'notificationShow',
          meta: {
            title: 'お知らせ',
          },
          component: () => import('./views/notifications/show'),
        },

        // Inspection
        {
          path: '/inspection/unconfirmed',
          name: 'inspectionUnconfirmed',
          meta: {
            title: '運行前点検 未確認一覧',
          },
          component: () => import('./views/inspection/unconfirmed'),
        },
        {
          path: '/inspection/confirmed',
          name: 'inspectionConfirmed',
          meta: {
            title: '運行前点検 確認済一覧',
          },
          component: () => import('./views/inspection/confirmed'),
        },
        {
          path: '/inspection/summary',
          name: 'inspectionSummary',
          meta: {
            title: '月別点検状況',
          },
          component: () => import('./views/inspection/summary'),
        },
      ],
    },

    // System Pages
    {
      path: '/user',
      component: LoginLayout,
      redirect: '/user/login',
      children: [
        {
          path: '/user/login',
          meta: {
            authUserNotAccess: true,
            title: 'Login',
          },
          component: () => import('./views/user/login'),
        },
        {
          path: '/user/forgot',
          meta: {
            title: 'Forgot Password',
          },
          component: () => import('./views/user/forgot'),
        },
      ],
    },

    // auth
    {
      path: '/2fa',
      component: LoginLayout,
      children: [
        {
          path: '/2fa/:user_id',
          meta: {
            title: '2段階認証',
          },
          component: () => import('./views/2fa/code_check'),
        },
      ],
    },
    {
      path: '/confirm',
      component: LoginLayout,
      children: [
        {
          path: '/confirm/:confirmation_token',
          meta: {
            title: 'パスワード変更',
          },
          component: () => import('./views/user/confirm'),
        },
      ],
    },
    {
      path: '/change_mail',
      component: LoginLayout,
      children: [
        {
          path: '/change_mail/:confirmation_token',
          meta: {
            title: 'メールアドレス変更',
          },
          component: () => import('./views/user/change_mail'),
        },
      ],
    },

    // Redirect to 404
    {
      path: '*',
      hidden: true,
      meta: {
        title: '404',
      },
      component: () => import('./views/404'),
    },
  ],
  parseQuery: (query) => {
    return queryString.parse(query, {
      arrayFormat: 'bracket',
    })
  },
  stringifyQuery: (params) => {
    if (Object.keys(params).length === 0) {
      return ''
    } else {
      return '?' + queryString.stringify(params, {
        arrayFormat: 'bracket',
      })
    }
  },
})

router.beforeEach((to, from, next) => {
  if (!store.state.user.access_token) {
    console.log('user not found in store')
    if (localStorage.getItem('app.user.accessToken') && localStorage.getItem('app.user.refreshToken') && localStorage.getItem('app.user.refreshToken')) {
      console.log('load user')
      const lastDate = localStorage.getItem('app.user.lastGetAccessToken')
      const today = moment()
      if (today.diff(moment(lastDate), 'days') > 50) {
        console.log('refresh token')
        Vue.prototype.$auth.refreshToken()
      } else {
        const accessToken = localStorage.getItem('app.user.accessToken')
        const refreshToken = localStorage.getItem('app.user.refreshToken')
        store.commit('UPDATE_ACCESS_TOKEN', { accessToken, refreshToken })
      }
    }
  }
  if (to.matched.some(record => record.meta.authRequired)) { // 認証が必要なページ
    if (store.state.user.access_token) {
      if (!store.state.user.name) {
        Vue.prototype.$auth.userInfo()
      }
      next()
    } else {
      next({
        path: '/user/login',
        query: { redirect: to.fullPath },
      })
    }
    // const check = Vue.prototype.$auth.authCheck()
    // check.then(function(res) {
    //   if (res) {
    //     next()
    //   } else {
    //     next({
    //       path: '/user/login',
    //       query: { redirect: to.fullPath },
    //     })
    //   }
    // })
  } else if (to.matched.some(record => record.meta.authUserNotAccess)) { // ログインページ
    if (store.state.user.access_token) {
      next({
        path: '/notifications/list',
        query: { redirect: to.fullPath },
      })
    } else {
      next()
    }
  //   const check = Vue.prototype.$auth.authCheck()
  //   check.then(function(res) {
  //     if (res) {
  //       next({
  //         path: '/',
  //         query: { redirect: to.fullPath },
  //       })
  //     } else {
  //       next()
  //     }
  //   })
  } else {
    next()
  }
})

export default router
